import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"; // para o toggle da linguagem
import { withTranslation, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-responsive-modal';
import ReactPlayer from "react-player";
import AppIcons from "../Utils/AppIcons";

import NavigationComponent from "./NavigationComponent";
import { setLanguage, setTheme, setStreamRefresh } from "../../store/actions/info";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {FaArrowCircleUp} from 'react-icons/fa';
import sendAnalyticsEvent from "../../scripts/analyticsEvent";


const Header = (props) => {

  const { t } = props;
  const { i18n } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const theme = useSelector((state) => state.info.theme);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [input, setInput] = useState("");
  const [open, setOpen] = useState(false);
  const [isHomepage, setIsHomepage] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
  }, [location])

  useEffect(() => {

    if (window.location.pathname === "/en" || window.location.pathname === "/ar") setIsHomepage(true);
    else setIsHomepage(false);

  }, [window.location.pathname])

  useEffect(() => {

    if (locale === "arSA") {
      i18n.changeLanguage("ar");
    }
    else {
      i18n.changeLanguage("en");
    }

    document.dir = i18n.dir();
  }, [locale]);

  useEffect(() => {
    let themeLS;
    if (localStorage) {
      themeLS = localStorage.getItem("theme");
    }
    themeLS = themeLS ? themeLS : "light";
    if (themeLS == "dark") {
      document.body.classList.add("dark-mode");
      dispatch(setTheme("dark"));
    } else if (themeLS == "light") {
      document.body.classList.remove("dark-mode");
      dispatch(setTheme("light"));
    }
  }, [theme, dispatch]);

  const changeLanguageEN = () => {
    dispatch(setLanguage("enUS"));
    history.push("/en");
  };

  const changeLanguageAR = () => {
    dispatch(setLanguage("arSA"));
    history.push("/ar");
  };

  const changeDark = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (value) {
      sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click",value:"dark", label: "theme"});
      dispatch(setTheme("dark"));
      localStorage.setItem("theme", "dark");
    } else {
      sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click",value:"light", label: "theme"});
      dispatch(setTheme("light"));
      localStorage.setItem("theme", "light");
    }
  };

  const onOpenModal = () => {
    setOpen(true);
    dispatch(setStreamRefresh(false));
  };

  const onCloseModal = () => {
    setOpen(false);
    dispatch(setStreamRefresh(true));
  };

  const ScrollButton = () =>{

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      }
      else if (scrolled <= 300){
        setVisible(false)
      }
    };

    const scrollToTop = () =>{
      sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "scroll top"});
      window.scrollTo({
        top: 0,
        behavior: 'smooth'

      });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
    <div className="scrollTop">
     <FaArrowCircleUp onClick={scrollToTop}
     style={{display: visible ? 'inline' : 'none'}} />
    </div>
    );
  }

  return (
    <header className={isMobile ? "header-fixed" : "header-absolute"}>
      <div className="top_bar">
        <div className="container">
          <div className="row align-content-center">
            <div className="col-12 d-flex justify-content-between position-relative">
              <div className="d-none d-md-flex">
                {/* LANGUAGE TOGGLE BUTTON */}
                {/* <div className="dropdown align-self-center">
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle className="clean_dropdown">
                      <a
                        className="btn dropdown-toggle clean_dropdown language"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {t("language")}
                      </a>
                    </DropdownToggle>
                    <DropdownMenu>
                      <a onClick={changeLanguageEN}>
                        <DropdownItem className={locale === "enUS" ? "active" : ""}>
                          {t("languageList.english")}
                        </DropdownItem>
                      </a>
                      <a onClick={changeLanguageAR}>
                        <DropdownItem className={locale === "enUS" ? "" : "active"}>
                          {t("languageList.arabic")}
                        </DropdownItem>
                      </a>
                    </DropdownMenu>
                  </Dropdown>
                </div> */}
                <div className="align-self-center toggle_wrapper">
                  <input
                    type="checkbox"
                    id="modeToggle"
                    checked={theme == "dark"}
                    onChange={changeDark}
                  />
                  <label htmlFor="modeToggle">
                    <div className="toggle"></div>
                  </label>
                </div>
              </div>


              <Link
                onClick={() => {sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "back to home"});dispatch({ type: "CHANGED_PATH", path: `/${locale === "arSA" ? "ar" : "en"}` })}}
                to={`/${locale === "arSA" ? "ar" : "en"}`}
                title="Back to home">
                <h1 style={{ marginTop: 0 }} >{/* {t("header.title")} */}</h1>
              </Link>

              <div className="d-flex">
                <div className="align-self-center d-none d-lg-block">

                  <ul className="nav_social mr-3 align-self-center mr-3">
                    <li className="mr-3" style={{fontSize: '1.4rem'}}>
                      <Link onClick={() => {sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "twitter"})}}
                            target="_blank" rel="noopener noreferrer"
                            to={{pathname: locale === "enUS" ? "https://twitter.com/ekhnews_en?s=21" : "https://twitter.com/alekhbariyatv?s=21"}}
                            alt="Goto alekhbariya twitter" title="twitter">
                        <i className="fa-brands fa-x-twitter"></i>
                      </Link>
                    </li>
                    <li className="mr-3" style={{fontSize: '1.4rem'}}>
                      <Link onClick={() => {sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "facebook"})}}
                            target="_blank" rel="noopener noreferrer"
                            to={{pathname:"https://www.facebook.com/alekhbariya.sba"}}
                            alt="Goto alekhbariya facebook" title="facebook">
                        <i className="fa-brands fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="mr-3" style={{fontSize: '1.4rem'}}>
                      <Link onClick={() => {sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "instagram"})}}
                            target="_blank" rel="noopener noreferrer"
                            to={{pathname:"https://www.instagram.com/alekhbariya.tv"}}
                            alt="Goto alekhbariya instagram" title="instagram">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li style={{fontSize: '1.4rem'}}>
                      <Link onClick={() => {sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "youtube"})}}
                            target="_blank" rel="noopener noreferrer"
                            to={{pathname:"https://youtube.com/ekhbariyatv"}}
                            alt="Goto alekhbariya youtube" title="youtube">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul >
                </div>
                <div className="align-self-center">
                  <form className="header_search">
                    <input
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      className="header_search_input"
                      type="search"
                      placeholder={t("header.search")}
                      aria-label="Search"
                    />
                    <Link to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/ekhsearch`, search: `${input.charAt(0) === '#' ? "?tag="+input.substring(1) : "?viewall="+input} ` }}>
                      <button
                        onClick={() =>{
                          sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"input", label: "search", value:input});
                          dispatch({type: "CHANGED_PATH",
                                    path: `/${locale === "arSA" ? "ar" : "en"}/ekhsearch?viewall=${input}`
                        })}


                        }
                        className="search_button"
                        type="submit"
                      >
                        <i className="fa-regular fa-magnifying-glass"></i>  
                      </button>
                    </Link>
                  </form>
                </div>
              </div>
            </div >
          </div >
        </div >
      </div >
      <NavigationComponent />

      <AppIcons props={props} />

      {/* BREAKING NEWS */}
      {isHomepage &&

        <>
          <div
            onClick={onOpenModal}
            className={
              scrolled ? "live_button d-flex fixed-live" : "live_button d-flex"
            }
          >
            <Link onClick={() => {sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "live streaming"})}} to="#" className="align-self-center stretched-link">
              {t("home.live")}
            </Link>
          </div>
          <>
            {open == true ?
              <Modal open={open} onClose={onCloseModal}
                showCloseIcon={false} closeOnOverlayClick={true}
                styles={{
                  content: {
                    overflow: "visible",
                  },
                  modal: {
                    maxWidth: "unset",
                    width: "80%",
                    padding: "unset",
                    overflow: "visible",
                    background: "transparent",
                  },
                  overlay: {
                    background: "rgba(0 0 0 / 97%)",

                  },
                  /* closeButton: {
                    background: "grey",
                    opacity:"0.75",
                    position: "fixed",
                    top: "1rem",
                    right: "1rem",
                    zIndex: "3000",
                              },  */
                }}
                center>
                <button onClick={onCloseModal} className="close">
                </button>
                <ReactPlayer
                  url='https://youtu.be/mn4DlkkqfAo' height="100%"
                  controls={true}
                  autoplay={true}
                  playing={true}
                  width="100%"
                />

              </Modal> : null}
          </>

        </>
      }
      <ScrollButton></ScrollButton>
    </header >
  );
};

export default withTranslation()(Header);
